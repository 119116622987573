@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap");

body {
  margin: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  background: rgb(204, 204, 204);
  color: var(--primary-color);
}

* {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media print {
  body,
  page {
    margin: 0;
    box-shadow: 0;
  }
}

:root {
  --primary-color: #37474f;
  --secondary-color: #455a64;
  --accent-color: #2ecc71;
  --title-font: "Roboto Condensed", sans-serif;
}


